"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceFieldsWatcher = void 0;
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const jsvat_next_1 = require("jsvat-next");
const react_query_1 = require("@tanstack/react-query");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const InvoiceFieldsWatcher = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.account]);
    const { control, setError, setValue } = (0, react_hook_form_1.useFormContext)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [vatNumber] = (0, react_hook_form_1.useWatch)({
        control,
        name: ['invoice.vatNumber'],
    });
    const { getCompanyDetailsByVAT } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: companyDetails, error: companyDetailsError } = (0, react_query_1.useQuery)(getCompanyDetailsByVAT({ country: vatNumber.slice(0, 2), vatNumber: vatNumber.slice(2) }, {
        enabled: (0, jsvat_next_1.checkVAT)(vatNumber, jsvat_next_1.countries).isValid,
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (companyDetailsError) {
            setError('vatNumber', t('buy.error.no.results'));
        }
    }, [companyDetailsError]);
    (0, react_1.useEffect)(() => {
        if (!companyDetails) {
            return;
        }
        if (!companyDetails.isVat) {
            setError('invoice.vatNumber', t(common_types_1.ErrorMessage.INVALID_VAT_NUMBER));
        }
        setValue('invoice.companyName', companyDetails.viesResponse.name, { shouldValidate: true });
        setValue('finance.isVat', companyDetails.isVat);
        setValue('finance.isViesVerified', companyDetails.isViesVerified);
    }, [companyDetails]);
    return null;
};
exports.InvoiceFieldsWatcher = InvoiceFieldsWatcher;
