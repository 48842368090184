"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointContainer = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const PickupPointSelected_1 = require("./PickupPointSelected");
const PickupPointSelector_1 = require("./PickupPointSelector");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const CustomerDetailsFields_1 = require("../CustomerDetailsFields");
const CustomerDetailsPreview_1 = require("../CustomerDetailsPreview");
const common_types_1 = require("@as-react/common-types");
const checkout_api_1 = require("@as-react/common-queries/dist/queries/checkout/checkout.api");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliverySubmitForm_1 = require("../DeliverySubmitForm");
const useFeatureToggle_1 = require("../../../../hooks/useFeatureToggle");
const CustomerUtil_1 = require("../../../../util/CustomerUtil");
const InvoiceFields_1 = require("../../../form/invoice-fields/InvoiceFields");
const PickupPointContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.general]);
    const [{ info, errors, activeDeliveryOption, editCustomerDetails }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const [{ pickupPoint }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const buyDelivery = (0, ConfigurationUtil_1.getBuyDelivery)(aemConfiguration);
    const isEnableFullVatForm = (0, ConfigurationUtil_1.getEnableFullVatForm)(aemConfiguration);
    const checkoutHideInvoice = (0, useFeatureToggle_1.useFeatureToggle)('checkout_hide_invoice');
    const isInvoiceEnabled = CustomerUtil_1.default.isInvoiceEnabled(checkoutHideInvoice, info);
    const { selectedPickupPoint } = pickupPoint;
    const { pickupInformation, country, customerDetails } = info;
    const showCustomerDetailsForm = editCustomerDetails || !customerDetails;
    (0, react_1.useEffect)(() => {
        const validateActivePickupPoint = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!activeDeliveryOption || (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.name) !== common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
                return;
            }
            if (!pickupInformation) {
                dispatch({ type: 'set_is_open', payload: true });
                return;
            }
            try {
                const data = yield (0, checkout_api_1.getPickupPoints)({ servicesEndpoint, defaultRequestParams }, {
                    countryCode: country,
                    provider: activeDeliveryOption.provider,
                    postcode: pickupInformation.properties.customerPostalCode,
                    deliveryDate: activeDeliveryOption.sendDate,
                    limit: buyDelivery.maxPickupPoints,
                });
                if (data.data) {
                    const activePoint = (_a = data.data) === null || _a === void 0 ? void 0 : _a.find(point => point.id === pickupInformation.properties.locationId);
                    if (activePoint) {
                        dispatch({ type: 'select_pickup_point', payload: activePoint });
                        return;
                    }
                }
                dispatch({ type: 'set_is_open', payload: true });
                resetPickupPoint();
            }
            catch (error) {
                dispatch({ type: 'set_is_open', payload: true });
                resetPickupPoint();
            }
        });
        validateActivePickupPoint();
    }, [activeDeliveryOption, pickupInformation, country]);
    const resetPickupPoint = () => {
        dispatch({ type: 'select_pickup_point', payload: null });
        dispatch({ type: 'highlight_pickup_point', payload: null });
    };
    const handleEditCustomerDetails = () => {
        dispatch({ type: 'edit_customer_details', payload: true });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [selectedPickupPoint && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: showCustomerDetailsForm ? '4' : undefined, children: [(0, jsx_runtime_1.jsx)(PickupPointSelected_1.PickupPointSelected, {}), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsxs)(DeliverySubmitForm_1.DeliverySubmitForm, { children: [showCustomerDetailsForm ? ((0, jsx_runtime_1.jsx)(CustomerDetailsFields_1.CustomerDetailsFields, {})) : ((0, jsx_runtime_1.jsx)(CustomerDetailsPreview_1.CustomerDetailsPreview, { onEdit: handleEditCustomerDetails })), isInvoiceEnabled && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "4", children: (0, jsx_runtime_1.jsx)(InvoiceFields_1.InvoiceFields, { showPreview: true, country: info.country, fullVatFormEnabled: isEnableFullVatForm }) }))] })] }) }) })), activeDeliveryOption && errors[activeDeliveryOption.name] && ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: t(errors[activeDeliveryOption.name]), status: "error" })), (0, jsx_runtime_1.jsx)(PickupPointSelector_1.PickupPointSelector, {})] }));
};
exports.PickupPointContainer = PickupPointContainer;
