"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostcodeHouseNumberWatcher = void 0;
const react_hook_form_1 = require("react-hook-form");
const react_1 = require("react");
const common_components_1 = require("@as-react/common-components");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const addressFields_utils_1 = require("../addressFields.utils");
const common_types_1 = require("@as-react/common-types");
const ArrayUtil_1 = require("../../../../util/ArrayUtil");
const PostcodeHouseNumberWatcher = ({ prefix, countryId }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [postalCodeFieldName, houseNumberFieldName] = (0, ArrayUtil_1.mapWithAssert)([common_types_1.AddressFieldName.POSTCODE, common_types_1.AddressFieldName.HOUSE_NUMBER], name => (0, addressFields_utils_1.generateFieldName)(name, prefix));
    const { control, setError, setValue, clearErrors } = (0, react_hook_form_1.useFormContext)();
    const [postalCode, houseNumber] = (0, react_hook_form_1.useWatch)({
        control,
        name: [postalCodeFieldName, houseNumberFieldName],
    });
    const { getAddresses } = (0, common_queries_1.generalQueries)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { data: addresses, error: addressError } = (0, react_query_1.useQuery)(getAddresses({ countryId, postalCode: postalCode, houseNumber: houseNumber }, {
        enabled: (0, addressFields_utils_1.isValidAddress)(postalCode, houseNumber, common_types_1.CountryId.NL),
        retry: false,
    }));
    (0, react_1.useEffect)(() => {
        if (addressError) {
            setError(postalCodeFieldName, t('buy.error.no.results'));
        }
    }, [addressError]);
    (0, react_1.useEffect)(() => {
        if (!addresses || addresses.length === 0) {
            return;
        }
        const firstAddress = addresses[0];
        if (firstAddress.address) {
            setValue('address', firstAddress.address, { shouldValidate: true });
            clearErrors('address');
        }
        if (firstAddress.city) {
            setValue('city', firstAddress.city);
            clearErrors('city');
        }
        if (firstAddress.houseNumberAddition) {
            setValue('houseNumberAddition', firstAddress.houseNumberAddition);
            clearErrors('houseNumberAddition');
        }
    }, [addresses]);
    return null;
};
exports.PostcodeHouseNumberWatcher = PostcodeHouseNumberWatcher;
