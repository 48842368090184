"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySubmitForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const react_router_1 = require("react-router");
const SessionStorageUtil_1 = require("../../../util/SessionStorageUtil");
const CheckoutDeliveryContext_1 = require("../../../context/CheckoutDeliveryContext");
const andes_react_1 = require("@yonderland/andes-react");
const BuyFormUtil_1 = require("../../../util/BuyFormUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const CustomerUtil_1 = require("../../../util/CustomerUtil");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const URLParamUtil_1 = require("../../../util/URLParamUtil");
const axios_1 = require("axios");
const BasketUtil_1 = require("../../../util/BasketUtil");
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const Yup = require("yup");
const yup_1 = require("@hookform/resolvers/yup");
const AddressUtil_1 = require("../../../util/AddressUtil");
const addressFields_utils_1 = require("../../form/address-fields/addressFields.utils");
const invoiceFields_utils_1 = require("../../form/invoice-fields/invoiceFields.utils");
const initialValuesCustomerDetailsForm = {
    idTitle: undefined,
    firstName: '',
    middleName: '',
    lastName: '',
    address: addressFields_utils_1.emptyAddressFields,
    showCustomTown: false,
    needInvoice: false,
    invoice: {
        vatCountry: '',
        vatNumber: '',
        companyName: '',
        companyDepartment: '',
        address: addressFields_utils_1.emptyAddressFields,
    },
};
// @TODO: temporary solution until person details step is implemented
const getInitialValues = (delivery, initValues, optionName, addressPrefilled = true, addressEdit = false) => {
    var _a, _b, _c, _d;
    if (!delivery.customerDetails) {
        return initValues;
    }
    let address = addressPrefilled
        ? optionName === common_types_1.DeliveryOptionsEnum.PICKUP_POINT
            ? delivery.customerDetails.address
            : delivery.deliveryAddress
        : {};
    if (addressEdit) {
        address = delivery.deliveryAddress;
    }
    const initialValues = Object.assign(Object.assign(Object.assign({}, initValues), { address: address, needInvoice: delivery.customerDetails.needInvoice, invoice: {
            vatCountry: ((_a = delivery.customerDetails.vatNumber) === null || _a === void 0 ? void 0 : _a.slice(0, 2)) || '',
            vatNumber: (_b = delivery.customerDetails.vatNumber) !== null && _b !== void 0 ? _b : '',
            companyName: (_c = delivery.customerDetails.companyName) !== null && _c !== void 0 ? _c : '',
            companyDepartment: (_d = delivery.customerDetails.companyDepartment) !== null && _d !== void 0 ? _d : '',
            address: delivery.customerDetails.invoiceAddress,
        } }), CustomerUtil_1.default.getCustomerNames(delivery, optionName));
    return initialValues;
};
const DeliverySubmitForm = ({ children }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const inAuthorMode = (0, ConfigurationUtil_1.getInAuthorMode)(aemConfiguration);
    const history = (0, react_router_1.useHistory)();
    const [{ info, activeDeliveryOption, deliveryCountryId }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { postDeliveryOption, postDelivery } = (0, common_queries_1.checkoutMutations)({
        servicesEndpoint,
        defaultRequestParams,
    });
    const { mutate: postDeliveryOptionMutation } = (0, react_query_1.useMutation)(postDeliveryOption);
    const { mutate: postDeliveryMutation, isPending, isError } = (0, react_query_1.useMutation)(postDelivery);
    const isProviderPostNL = (activeDeliveryOption === null || activeDeliveryOption === void 0 ? void 0 : activeDeliveryOption.provider) === common_types_1.Provider.POSTNL;
    const customerValidationSchema = Yup.object({
        idTitle: Yup.number(),
        firstName: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`)),
        lastName: Yup.string().required(t(`${i18nConstants_1.I18N_NAMESPACES.account}:delivery.required`)),
        middleName: Yup.string(),
        address: (0, AddressUtil_1.getAddressValidationSchema)({ country: info.country, t, isOptional: !isProviderPostNL }),
    });
    const validationSchema = customerValidationSchema.concat((0, invoiceFields_utils_1.getInvoiceValidationSchema)({ t, country: info.country }));
    const values = getInitialValues(info, initialValuesCustomerDetailsForm, info.chosenDeliveryOption);
    (0, react_1.useEffect)(() => {
        if (isError) {
            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
        }
    }, [isError]);
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const isValidCustomer = yield customerValidationSchema.isValid(values);
            if (!isValidCustomer) {
                dispatch({ type: 'edit_customer_details', payload: true });
            }
        });
        validateForm();
    }, [info]);
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: values,
        mode: 'all',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const onSubmit = values => {
        var _a, _b, _c;
        SessionStorageUtil_1.default.removeItem(StorageKeysConstants_1.GUEST_USER_CHECKOUT_DATA);
        /**
         * Comments on this block of code will be added in the next PR
         */
        const delivery = Object.assign({}, info);
        let invoiceAddress = {};
        if (!delivery.customerDetails) {
            delivery.customerDetails = {};
        }
        if (info.pickupInformation && deliveryCountryId) {
            delivery.pickupInformation = Object.assign(Object.assign({}, info.pickupInformation), { properties: Object.assign(Object.assign({}, info.pickupInformation.properties), { countryId: String(deliveryCountryId) }) });
        }
        // Basic delivery info
        delivery.deliveryAddress = Object.assign(Object.assign({}, delivery.deliveryAddress), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle });
        // Attach address when needed
        if (delivery.chosenDeliveryOption !== common_types_1.DeliveryOptionsEnum.PICKUP_POINT && info.requiresDelivery) {
            delivery.deliveryAddress = Object.assign(Object.assign(Object.assign({}, delivery.deliveryAddress), values.address), { idCountry: delivery.idCountry, country: delivery.country });
        }
        // @TODO check if this is needed
        // if ((values.saveAddress && !updateDeliveryAddress) || newDeliveryAddress) {
        //   delete delivery.deliveryAddress.deliveryAddressId;
        // }
        // Check if delivery address is an US address
        if (delivery.idCountry === common_types_1.CountryId.US) {
            delivery.deliveryAddress = Object.assign({}, delivery.deliveryAddress);
        }
        if (delivery.chosenDeliveryOption === common_types_1.DeliveryOptionsEnum.PICKUP_POINT) {
            delivery.customerDetails.address = Object.assign(Object.assign({}, delivery.customerDetails.address), values.address);
        }
        if ((_b = (_a = delivery.customerDetails) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.address) {
            invoiceAddress = Object.assign({}, delivery.customerDetails.address);
        }
        if ((_c = values.invoice.address) === null || _c === void 0 ? void 0 : _c.address) {
            invoiceAddress = Object.assign(Object.assign({}, values.invoice.address), { idCountry: info.idCountry, country: info.country });
        }
        delivery.customerDetails.invoiceAddress = invoiceAddress;
        delivery.customerDetails.needInvoice = values.needInvoice;
        // delivery.customerDetails.alternateInvoiceAddress = values.alternateInvoiceAddress;
        delivery.customerDetails.companyDepartment = values.invoice.companyDepartment;
        delivery.customerDetails.companyName = values.invoice.companyName;
        delivery.customerDetails.vatNumber = values.invoice.vatNumber;
        if (CustomerUtil_1.default.isUpdateNamesAndTitleForCustomerDetails(delivery)) {
            delivery.customerDetails = Object.assign(Object.assign({}, delivery.customerDetails), { firstName: values.firstName, middleName: values.middleName, lastName: values.lastName, idTitle: values.idTitle });
        }
        if (info.chosenDeliveryOption) {
            postDeliveryOptionMutation(info.chosenDeliveryOption);
        }
        postDeliveryMutation(delivery, {
            onSuccess: () => {
                history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.PAYMENT}`, inAuthorMode));
            },
            onError: error => {
                var _a;
                if ((0, axios_1.isAxiosError)(error)) {
                    if (BasketUtil_1.default.isBasketNotInitialized(error)) {
                        location.href = `${root}checkout/basket-overview.html`;
                        return;
                    }
                    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.find((item) => item.code === 'non_numeric_field')) {
                        dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.INVALID_POSTAL_CODE });
                    }
                    else {
                        if ((0, BuyFormUtil_1.isUkCountry)(delivery.country)) {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.GENERAL_ADDRESS_BUY });
                        }
                        else {
                            dispatch({ type: 'set_delivery_error', payload: common_types_1.ErrorMessage.API_ERROR });
                        }
                    }
                }
            },
        });
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsxs)("form", { onSubmit: formProps.handleSubmit(onSubmit), noValidate: true, name: "checkout-customer-details", children: [children, (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "4", children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { state: isPending || (!activeDeliveryOption && info.requiresDelivery) ? 'disabled' : undefined, text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.delivery.step.continue`), type: "submit" }) })] }) })));
};
exports.DeliverySubmitForm = DeliverySubmitForm;
