"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PickupPointSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_dom_1 = require("react-dom");
const PickupPointSearchForm_1 = require("./PickupPointSearchForm");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const PickupPoint_1 = require("./PickupPoint");
const CheckoutDeliveryContext_1 = require("../../../../context/CheckoutDeliveryContext");
const StoreListLoader_1 = require("../../../product-detail/store-finder-popup/store-list-loader/StoreListLoader");
const react_query_1 = require("@tanstack/react-query");
const common_queries_1 = require("@as-react/common-queries");
const common_components_1 = require("@as-react/common-components");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const SIDEBAR_WIDTH = '512px';
const PickupPoints = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [{ pickupPoint }] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { highlightedPickupPoint, selectedPickupPoint } = pickupPoint;
    const { getPickupPointsQuery, locationSearch } = (0, CheckoutDeliveryContext_1.usePickupPointSearchContext)();
    const { data: pickupPointsResponse, isLoading } = getPickupPointsQuery;
    if (isLoading) {
        // @TODO: Implement this PickupPoint variant?
        return (0, jsx_runtime_1.jsx)(StoreListLoader_1.StoreListLoader, {});
    }
    if (!pickupPointsResponse || !locationSearch)
        return null;
    if (pickupPointsResponse.status === common_types_1.HTTPStatusCode.NoContent) {
        return (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t('checkout:checkout.delivery.pickuppoint.no.results') });
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "4", children: pickupPointsResponse.data.map(pickupPoint => ((0, jsx_runtime_1.jsx)(PickupPoint_1.PickupPoint, { pickupPoint: pickupPoint, selected: (highlightedPickupPoint === null || highlightedPickupPoint === void 0 ? void 0 : highlightedPickupPoint.id) === pickupPoint.id || (selectedPickupPoint === null || selectedPickupPoint === void 0 ? void 0 : selectedPickupPoint.id) === pickupPoint.id }, pickupPoint.id))) }));
};
const PickupPointSelector = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const [{ pickupPoint, activeDeliveryOption, deliveryCountryId }, dispatch] = (0, CheckoutDeliveryContext_1.useDeliveryState)();
    const { highlightedPickupPoint, selectedPickupPoint, isOpen } = pickupPoint;
    const { setLocationSearch } = (0, CheckoutDeliveryContext_1.usePickupPointSearchContext)();
    const { postPickupPoint } = (0, common_queries_1.checkoutMutations)({ servicesEndpoint, defaultRequestParams });
    const postPickupPointMutation = (0, react_query_1.useMutation)(postPickupPoint);
    const selectPickupPoint = () => {
        if (!highlightedPickupPoint)
            return;
        postPickupPointMutation.mutate({
            countryCode: String(deliveryCountryId),
            provider: activeDeliveryOption.provider,
            pickupPoint: highlightedPickupPoint,
        }, {
            onSuccess: () => {
                dispatch({ type: 'select_pickup_point', payload: highlightedPickupPoint });
            },
            onError: () => {
                dispatch({ type: 'set_active_delivery_option', payload: undefined });
            },
            onSettled: () => {
                dispatch({ type: 'set_is_open', payload: false });
                setLocationSearch('');
            },
        });
    };
    const resetDeliveryOption = () => {
        dispatch({ type: 'highlight_pickup_point', payload: null });
        dispatch({ type: 'set_is_open', payload: false });
        setLocationSearch('');
        if (!selectedPickupPoint) {
            dispatch({ type: 'set_active_delivery_option', payload: undefined });
        }
    };
    const getContent = () => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", children: t('checkout:checkout.delivery.pickuppoint.flyout.title') }), (0, jsx_runtime_1.jsx)(PickupPointSearchForm_1.PickupPointSearchForm, {}), (0, jsx_runtime_1.jsx)(PickupPoints, {})] }));
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { until: "sm", children: (0, jsx_runtime_1.jsxs)(andes_react_1.BottomSheet, { isOpen: isOpen, fullHeightOnScroll: true, onClose: resetDeliveryOption, padding: "none", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "6", paddingBlockEnd: "20", children: getContent() }), !!highlightedPickupPoint && ((0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: true, side: "right", align: "bottom", position: "fixed", animation: "none", zIndex: "modal", hasCloseBtn: false, hasBackdrop: false, fullHeight: false, customWidth: { width: '100%' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, text: t('checkout:checkout.delivery.pickuppoint.flyout.confirm'), onClick: selectPickupPoint }) }))] }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Breakpoint, { from: "sm", children: (0, jsx_runtime_1.jsx)(andes_react_1.Sidebar, { isOpen: isOpen, onClose: resetDeliveryOption, side: "right", align: "top", position: "fixed", customWidth: { width: SIDEBAR_WIDTH }, hasCloseBtnSpacing: false, footerSticky: true, footerContent: !!highlightedPickupPoint && ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { fullWidth: true, theme: "branded", text: t('checkout:checkout.delivery.pickuppoint.flyout.confirm'), onClick: selectPickupPoint })), children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "6", children: getContent() }) }) })] }), document.body);
};
exports.PickupPointSelector = PickupPointSelector;
